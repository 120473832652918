







































































import { required, minLength, between } from 'vuelidate/lib/validators';
import ObjectManager from '../../services/object_manager';
import { AgGridVue } from "ag-grid-vue";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import VueGridLayout from 'vue-grid-layout';
import numeral from "numeral";
import {Exchange} from "../../model/ExchangeModel";

export default {
    components: {
      AgGridVue,
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem
    },
    data() {
        return {
            errorMessages: [],
            gridOptions: {} as any,
            gridApi: {} as any,
            columnDefs: {},
            rowGroupPanelShow: {},
            pivotPanelShow: {},
            asyncTransactionWaitMillis: {},
            getRowNodeId: {},
            defaultColDef: {},
            autoGroupColumnDef: {},
            detailCellRendererParams: {},
            sideBar: {},
            globalRowData: [] as [],
            height: '600px',
            width: '100%',
            layout: [
                {"x":0,"y":0,"w":12,"h":15.7,"i":"0"},
                // {"x":0,"y":0,"w":2,"h":4,"i":"1"},
                // {"x":2,"y":0,"w":2,"h":5,"i":"2"},
                // {"x":4,"y":0,"w":2,"h":3,"i":"3"},

            ],
            dashboardName:"marketdata-livemonitor" as string,
        }
    },
    directives: {
    },
    beforeMount() {
        this.gridOptions = {};
        this.columnDefs = [
        {
            headerName: 'Product',
            field: 'product',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            // rowGroupIndex: 0,
        },
        {
            headerName: 'Symbol',
            field: 'symbol',
            enableRowGroup: true,
            enablePivot: true,
            hide: false,
            filter: 'agTextColumnFilter'
            // rowGroupIndex: 0,
        },
        {
            headerName: 'Last Price',
            field: 'last_price',
            aggFunc: 'sum',
            enableValue: true,
            enableRowGroup: true,
            cellClass: 'number',
            valueFormatter: this.numberCellFormatter,
        },
        {
            headerName: 'Bid',
            field: 'bid',
            aggFunc: 'sum',
            enableValue: true,
            enableRowGroup: true,
            cellClass: 'number',
            valueFormatter: this.numberCellFormatter,
            //cellRenderer: 'agAnimateShowChangeCellRenderer',
        },
        {
            headerName: 'Ask',
            field: 'ask',
            aggFunc: 'sum',
            enableValue: true,
            enableRowGroup: true,
            cellClass: 'number',
            valueFormatter: this.numberCellFormatter,
            //cellRenderer: 'agAnimateShowChangeCellRenderer',
        },
        {
            headerName: 'Exchange',
            field: 'exchange',
            aggFunc: 'sum',
            enableValue: true,
            enableRowGroup: true,
            //cellRenderer: 'agAnimateShowChangeCellRenderer',
        },
        ];
        this.rowGroupPanelShow = 'always';
        this.pivotPanelShow = 'always';
        this.asyncTransactionWaitMillis = 100;
        this.getRowNodeId = (data) => {
            return data.id;
        };
        this.defaultColDef = {
            flex:1,
            sortable: true,
            resizable: true,
            filterParams: {newRowsAction: 'keep'}
        };
        this.autoGroupColumnDef = { width: 250 };
        this.detailCellRendererParams = {
            refreshStrategy: 'rows',
            template: (params) => {
                return (
                    '<div class="ag-details-row ag-details-row-fixed-height">' +
                    '<div style="padding: 4px; font-weight: bold;">' +
                    params.data.name +
                    ' ' +
                    params.data.calls +
                    ' calls</div>' +
                    '<div ref="eDetailGrid" class="ag-details-grid ag-details-grid-fixed-height"/>' +
                    '</div>'
                );
            },
        }
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: false,
                        suppressValues: true,
                    }
                }
            ],
            position: 'left',
            hiddenByDefault: false,
        };
    },
    // detailGridOptions: {
    //     rowSelection: 'multiple',
    //     enableCellChangeFlash: true,
    //     immutableData: true,
    //     getRowNodeId: function (data) {
    //       return data.callId;
    //     }
    // },
    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        this.restoreState();
    },
    created() {
    },
    beforeDestroy() {
        this.$root.bus.$off('marketDataEvent');
        this.$root.bus.$emit("CancelMarketData", true);
        this.saveState();
    },
    destroyed() {
        this.gridApi.destroy();
    },
    methods: {
        startFeed() {
            this.$root.bus.$emit("SubscribeMarketData", true);
            var createRecord = this.createInstrumentRecord;
            var grid = this.gridApi;
            this.$root.bus.$on('marketDataEvent', (res) => {
                try {
                    let object_response = res.marketDataEvent.instrument;
                    let newItem = createRecord(object_response.id, 'CRYPTO', object_response.symbol, object_response.valuationList[2].value, object_response.valuationList[0].value, object_response.valuationList[1].value, object_response.exchange)
                    grid.applyTransactionAsync({ update: [newItem] }, null);
                } catch (e) {
                    //console.log(e);
                }
            });
        },
        createRowData(api) {
            this.globalRowData = [];
            //let res = this.marketdata.Snapshot();
            let newItem1 = this.createInstrumentRecord('a35db612-e747-5596-948b-6a7a372014f2', 'CRYPTO','BTCUSD', 0, 0, 0, 4);
            let newItem2 = this.createInstrumentRecord('f30cb3c8-7487-52eb-9c65-43175e7dfea5', 'CRYPTO','ETHUSD', 0, 0, 0, 4);
            let newItem3 = this.createInstrumentRecord('51c0dfe6-cf8a-539c-9229-d818811af206', 'CRYPTO','BRZUSD', 0, 0, 0, 4);
            let newItem4 = this.createInstrumentRecord('aa9c2cee-cd56-5ff8-9455-69f07b4aba42', 'CRYPTO','USDBRZ', 0, 0, 0, 4);
            let newItem5 = this.createInstrumentRecord('1d1ecd70-e08c-580e-b038-335c150977fc', 'CRYPTO','ETHUSD', 0, 0, 0, 5);
            let newItem6 = this.createInstrumentRecord('d7c4b9ed-c865-5c95-9c0d-9196abe31464', 'CRYPTO','BTCUSD', 0, 0, 0, 5);
            let newItem7 = this.createInstrumentRecord('dcd0abb4-9538-5811-a338-3ebe8b2f6452', 'CRYPTO','BUSDBRL', 0, 0, 0, 5);
            let newItem8 = this.createInstrumentRecord('c0fd82f5-a640-5f3a-81ca-ed5c78515c7a', 'CRYPTO','BTCBRL', 0, 0, 0, 5);
            let newItem9 = this.createInstrumentRecord('d666bf6e-798a-5af7-8472-cdd8dbbae73f', 'CRYPTO','MATICBRL', 0, 0, 0, 5);
            let newItem10 = this.createInstrumentRecord('e98d0bd3-ed7d-57c1-8cc9-f7a9d3612c3a', 'CRYPTO','ETHBRL', 0, 0, 0, 5);
            this.globalRowData.push(newItem1);
            this.globalRowData.push(newItem2);
            this.globalRowData.push(newItem3);
            this.globalRowData.push(newItem4);
            this.globalRowData.push(newItem5);
            this.globalRowData.push(newItem6);
            this.globalRowData.push(newItem7);
            this.globalRowData.push(newItem8);
            this.globalRowData.push(newItem9);
            this.globalRowData.push(newItem10);
            this.gridApi.setRowData(this.globalRowData);
            // res.then(
            //     response  => {
            //         let object_response = response.toObject().instrumentList;
            //         for (let index in object_response) {
            //             let value = object_response[index].valuationList.reduce(function(filtered, option) {
            //                 filtered.push(option.value);
            //             return filtered;
            //             }, []);
            //             this.globalRowData.push(this.createInstrumentRecord('EQUITY', 'LONG', object_response[index].symbol, value[0]));
            //         }
            //         api.setRowData(this.globalRowData);
            //     },
            //     error => { 
            //     }
            // );
        },
        copyObject(object) {
            let newObject = {};
            Object.keys(object).forEach(function (key) {
                newObject[key] = object[key];
            });
            return newObject;
        },
        createInstrumentRecord(id, product, symbol, last_price, bid, ask, exchange) {
            var instrument = {
                id: id,
                product: product,
                symbol: symbol,
                last_price: last_price,
                bid: bid,
                ask: ask,
                exchange: Exchange[exchange],
            };
            return instrument;
        },
        numberCellFormatter(params) {
            return `${numeral(params.value).format('0,0.00000')}`;
        },
        setWidthAndHeight(width, height) {
            this.width = `${width}px`;
            this.height = `${height}px`;
        },
        resizeEvent: function(i, newH, newW, newHPx, newWPx) {
            let adjust = -20;
            newHPx += adjust;
            newWPx += 1;
            if (i == 0) {
                this.setWidthAndHeight(newWPx, newHPx);
            }
        },
        resizedEvent: function(i, newX, newY, newHPx, newWPx){
            let adjust = -20;
            newHPx += adjust;
            newWPx += 1;
            if (i == 0) {
                this.setWidthAndHeight(newWPx, newHPx);
            }
        },
        onFlushTransactions() {
            this.gridApi.flushAsyncTransactions();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.createRowData();
            this.startFeed();
        },
        saveState() {
            ObjectManager.setSetting(this.dashboardName, this.gridColumnApi.getColumnState());
        },
        restoreState() {
            if (!ObjectManager.getSetting(this.dashboardName)) {
                return;
            }
            this.gridColumnApi.applyColumnState({
                state: ObjectManager.getSetting(this.dashboardName),
                applyOrder: true,
            });
        },
    }
};
        
