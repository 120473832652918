











































import jwt_decode from 'jwt-decode';
export default {
    data() {
        let userToken:any = localStorage.getItem('user_token');
        let jwt:any = jwt_decode(userToken);
        return {
           errorMessages: [],
           user: {
               name: jwt.name,
               picture: jwt.picture ? jwt.picture : 'img/theme/profile.png'
           },
        }
    },
    mounted() {
    },
};
