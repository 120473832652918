<template>
    <footer class="footer has-cards">
        <div class="container">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-6">
                    <!--<h3 class="text-primary font-weight-light mb-2">Redes Sociais</h3>-->
                    <!--<h4 class="mb-0 font-weight-light">Entre em contato e retornaremos o mais breve possível.</h4>-->
                </div>
                <div class="col-lg-6 text-lg-center btn-wrapper">
                    <a target="_blank" rel="noopener" href="https://www.instagram.com/inovaquant"
                       class="btn btn-neutral btn-icon-only btn-instagram btn-lg btn-round" data-toggle="tooltip"
                       data-original-title="Follow us on Instagram">
                        <i class="fa fa-instagram"></i>
                    </a>
                    <a target="_blank" rel="noopener" href="https://www.linkedin.com/in/brunomarretti/"
                       class="btn btn-neutral btn-icon-only btn-linkedin btn-round btn-lg" data-toggle="tooltip"
                       data-original-title="Follow us on Linkdin">
                        <i class="fa fa-linkedin"></i>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; {{year}}
                        <a href="#" class="text-info" rel="noopener">It's Ramp</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                            <a href="#" class="nav-link" rel="noopener">It's Ramp</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link" rel="noopener">Sobre Nós</a>
                        </li>
                    </ul>
                </div>
            </div>
            <cookie-law buttonText="Ok" theme="dark-lime" class="mb-5">
                <div slot="message">
                    <h6 class="text text-default">Usamos cookies para aprimorar sua experiência de utilização e, ao continuar navegando neste site, você concorda com os nossos <router-link to="/terms-of-service">Termos de Uso e Política de Privacidade</router-link>.</h6>
                </div>
            </cookie-law>
        </div>

    </footer>
</template>
<style>
.Cookie {
    max-width: 55%;
    opacity: 0.9;
    margin: auto;
    background: #fff !important;
    border: 1px dotted black;
    padding: 1rem !important;
}
.Cookie__button {
    background: #172b4d !important;
}
</style>
<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
  components: { CookieLaw }
};
</script>
<style>
</style>
